import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { FdrLinkType, FdrMenu } from '@hotelplan/supergraph-api';
import { useFdrHeaderQuery } from 'fdr/schemas/query/header/fdr-header.generated';
import logoFallback from 'public/images/hotelplan_logo.svg';

export function useHeaderData() {
  const [t] = useTranslation('common');
  const { data, loading } = useFdrHeaderQuery();

  return useMemo(
    () => ({
      loading,
      logo:
        !loading && !data?.fdrHeader?.logo
          ? {
              alt: t('copyright.text'),
              rawUrl: logoFallback,
              resized: [
                {
                  id: '1x_desktop',
                  link: { type: FdrLinkType.Internal, url: logoFallback },
                },
                {
                  id: '1x_mobile',
                  link: { type: FdrLinkType.Internal, url: logoFallback },
                },
              ],
            }
          : data?.fdrHeader?.logo,
      menus: (data?.fdrHeader?.menus || []) as Array<FdrMenu>,
    }),
    [data, loading, t]
  );
}
